import React, { Fragment, useState, useContext } from "react";
import RICIBs from "react-individual-character-input-boxes";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update_golfer } from "../../features/golfer/golferSlice";
import JoinPageService from "../../services/api/join_page";
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { setTokenCookie } from '../../auth';
import { JoinPageContext } from '../../context/join_page_context';

export default function VerifyCode(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { club_id } = useParams();
  const golfer_data = useSelector((state) => state.golfer.golfer) || {};
  const logged_in_user_data = useSelector(state => state.loggedInUserSlice.logged_in_user_data)
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { JoinPageData } = useContext(JoinPageContext);

  const checkCode = () => {
    JoinPageService.verifyCode({
      code: code,
      golfer_id: golfer_data.id,
      club_id: club_id,
    })
      .then((res) => {
        if (res.token) {
          setTokenCookie(res.token, 'golfer');
        }
        const add_data_to_golfer = {
          primary_club_id: res.primary_club_id,
        };
        dispatch(update_golfer({ ...add_data_to_golfer }));
        navigate(`../golfer_details`);
      })
      .catch((e) => {
        console.log(e);
        if (e.data.code) {
          setError(e.data.code);
          return;
        }
        if (e.data.errors) {
          const errors = e.data.errors;
          try {
            setError(errors[Object.keys(errors)[0]][0]);
          } catch (e) {
            setError("An error has occurred. Please try again later.");
          }
          return;
        }
        setError("An error has occurred. Please try again later.");
      });
  };
  
  const clearInputValues = () => {
    let container = document.getElementById('code-container');
    let inputs = container.getElementsByTagName('INPUT');
    
    for(var i = 0; i < inputs.length; ++i) {
        var input = inputs[i];
        input.value = '';
      }
  }

  const sendVerificationCode = () => {
    JoinPageService.sendVerificationCode({
      club_id: club_id,
      golfer_id: golfer_data.id,
      email: logged_in_user_data.email,
      join_page_id: JoinPageData.id
    })
      .then(() => {
        setMessage("Code has been sent");
        setCode("");
      })
      .catch((e) => {
        console.log(e);
        if (e.data.errors) {
          const errors = e.data.errors;
          try {
            setError(errors[Object.keys(errors)[0]][0]);
          } catch (e) {
            setError("An error has occurred. Please try again later.");
          }
          return;
        }
        setError("An error has occurred. Please try again later.");
      });
  };

  return (
    <Fragment>
        <div className="box-panel center">
          <div className="container">
            <div className="center">
              <span className="header-text">
                <strong>
                  Enter the verification code sent to your email associated with
                  your GHIN account.
                </strong>
              </span>
            </div>

            <div style={{ marginTop: "30px" }} id="code-container">
              <RICIBs
                amount={6}
                autoFocus
                handleOutputString={(string) => setCode(string)}
                inputRegExp={/^[0-9]$/}
              />
          </div>
          <div className="row">
            <div className="col is-1-of-2">
              <button
                style={{ marginTop: '40px', fontWeight: '500' }}
                className="btn smaller fill gray"
                type={"button"}
                onClick={
                  () => navigate("../verify_golfer")
                }
              >
                Go Back
              </button>
            </div>
            <div className="col is-1-of-2">
              <button
                style={{ marginTop: "40px" }}
              className="btn smaller fill cardinal"
              disabled={code.length !== 6}
              type={"submit"}
              onClick={() => checkCode()}
            >
              Continue
            </button>
            </div>
          </div>

            <div className="note gray" style={{ marginTop: "20px" }}>
              Didn't receive it? Check your junk mail or{" "}
              <button
                className="custom-link gray"
                onClick={() => {sendVerificationCode(); clearInputValues();}}
              >
                Resend the code
              </button>
            </div>
        </div>
      </div>

      <ConfirmationModalTypeB
        wideClass={"responsive"}
        openModal={message.length > 0}
        onConfirmAction={() => {
          setMessage("");
        }}
        onCancelAction={() => {
          setMessage("");
        }}
        closeModal={() => {
          setMessage("");
        }}
        question={message}
        confirmLabel={"Confirm"}
        modalIcon={CheckCircleOutlineIcon}
      />

      <ConfirmationModalTypeB
        wideClass={"responsive"}
        openModal={error.length > 0}
        onConfirmAction={() => {
          setError("");
        }}
        onCancelAction={() => {
          setError("");
        }}
        closeModal={() => {
          setError("");
        }}
        question={error}
        confirmLabel={"Confirm"}
        modalIcon={ErrorOutlineIcon}
      />
    </Fragment>
  );
}
