import React, { Fragment, useEffect, useState, useContext } from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import InputField from "../../inputs/InputField";
import * as Yup from "yup";
import { update_golfer } from "../../features/golfer/golferSlice";
import { useDispatch } from "react-redux";
import JoinPageService from "../../services/api/join_page";
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { JoinPageContext } from '../../context/join_page_context';
import { update_logged_in_user } from "../../features/logged_in_user/loggedInUserSlice";
import { clear_golfer, clear_address, clear_billing_address, clear_guardian, clear_email } from '../../features/golfer/golferSlice';
import { clear_club } from '../../features/club/clubSlice';
import { clear_memberships, clear_selected_membership } from '../../features/membership/membershipSlice';
import { clear } from '../../features/payment/paymentSlice';
import { clear_selected } from '../../features/addons/addonsSlice';


export default function VerifyGolfer(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation();
  const { JoinPageData } = useContext(JoinPageContext);
  const [ghinNumber, setGhinNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [errors, setErrors] = useState(false);
  const [error, setError] = useState("");
  const { club_id } = useParams();
  const ghinNumberValidationSchema = {
    "GHIN Number": Yup.string().test('integer-shape', 'GHIN Number must be a number', function (value) {
      return (value === '' || /^\d+$/.test(value));
    }).required('GHIN Number is a required field and must be numeric.')
  };
  const emailValidationSchema = {
    "Email Address": Yup.string().required().email(),
  };
  const validationSchema = Yup.object({
    ...ghinNumberValidationSchema,
    ...emailValidationSchema,
  });
  
  const clearData = () => {
    sessionStorage.removeItem('auto_renew_failed');
    sessionStorage.removeItem('update_card_details');
    dispatch(clear_golfer());
    dispatch(clear_email());
    dispatch(clear_guardian());
    dispatch(clear_address());
    dispatch(clear_billing_address());
    dispatch(clear_club());
    dispatch(clear_memberships());
    dispatch(clear_selected_membership());
    dispatch(clear_selected());
    dispatch(clear());
  }
  
  useEffect(() => {
    clearData();
  }, [])

  useEffect(() => {
    const validateFields = () => {
      validationSchema
        .isValid({
          "GHIN Number": ghinNumber,
          "Email Address": emailAddress,
        })
        .then((res) => {
          setErrors(!res);
        });
    };
    validateFields();
  }, [ghinNumber, emailAddress, validationSchema]);

  const sendVerificationCode = () => {
    JoinPageService.sendVerificationCode({
      golfer_id: ghinNumber,
      email: emailAddress,
      club_id: club_id,
      join_page_id: JoinPageData.id
    })
      .then((res) => {
        const golfer_data = {
          id: ghinNumber,
        };
        dispatch(update_golfer({ ...golfer_data }));
        dispatch(update_logged_in_user({
          email: emailAddress,
          is_guardian: res.is_guardian
        }))
        navigate(`../code_verification`);
      })
      .catch((e) => {
        console.log(e);
        if (e.data.errors) {
          const errors = e.data.errors;
          try {
            setError(errors[Object.keys(errors)[0]][0]);
          } catch (e) {
            setError("An error has occurred. Please try again later.");
          }
          return;
        }
        setError("An error has occurred. Please try again later.");
      });
  };

  return (
    <Fragment>
        <div className="box-panel center">
          <div className="container">
            <span className="header-text">
              <strong>Enter your GHIN membership details</strong>
            </span>

              <div className="row">
                <div className="col-sm two">
                  <InputField
                      size=""
                      id="ghin_number"
                      label="GHIN Number"
                      value={ghinNumber}
                      type='number'
                      validationSchema={Yup.object(ghinNumberValidationSchema)}
                      onChange={(event) => setGhinNumber(event.target.value)}
                      onKeyDown={(e) =>["e", "E", "+", "-", '.', ','].includes(e.key) && e.preventDefault()}
                      requiredIcon
                  />
                </div>
                <div className="col-sm third">
                  <p className="text-bold">Forgot your GHIN Number?</p>
                  <p className="text-info">No problem! For help, contact the {JoinPageData.golf_association_details.name} (contact information below).</p>
                </div>
              </div>


            <InputField
              size=""
              id="email_address"
              label="Email Address"
              value={emailAddress}
              validationSchema={Yup.object(emailValidationSchema)}
              onChange={(event) => setEmailAddress(event.target.value)}
              requiredIcon
            />

            <div
              className="note gray"
              style={{ marginTop: "35px", maxWidth: "300px" }}
            >
              Please be sure this is the email associated with your GHIN
              membership.
            </div>

            <button
              style={{ marginTop: "30px" }}
              className="btn smaller fill cardinal"
              type={"submit"}
              disabled={errors}
              onClick={() => sendVerificationCode()}
            >
              Send Verification Code
            </button>

            {(state && state.previous_page === '') ||
              <div className="note gray" style={{marginTop: "20px"}}>
                No membership?{" "}
                <button
                  className="custom-link gray"
                  onClick={() => {
                    dispatch(update_golfer(false));
                    navigate(`../golfer`, {
                      state: {
                        previous_page: "membership_details",
                      },
                    });
                  }}
                >
                  Sign up
                </button>
              </div>
            }
        </div>
      </div>
      <ConfirmationModalTypeB
        wideClass={"responsive"}
        openModal={error.length > 0}
        onConfirmAction={() => {
          setError("");
        }}
        onCancelAction={() => {
          setError("");
        }}
        closeModal={() => {
          setError("");
        }}
        question={error}
        confirmLabel={"Confirm"}
        modalIcon={ErrorOutlineIcon}
      />
    </Fragment>
  );
}
