import { Fragment, useContext } from "react"
import { JoinPageContext } from "../context/join_page_context"

export default function InactiveJoinPage(props) {
  const { JoinPageData } = useContext(JoinPageContext)

  return (
    <Fragment>
      <div className='block-container'>
        <strong>
          <p style={{textAlign: 'center'}}>
          {JoinPageData.name} is currently inactive or setup is incomplete.
          <br></br>
          Please contact the {JoinPageData.golf_association_details.name} for assistance.
          </p>
        </strong>
      </div>
    </Fragment>
  )
}
