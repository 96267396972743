import './App.css';
import Home from "./screens/Home"
import {
  Route,
  Routes
} from "react-router-dom";
import Invalid from './screens/Invalid';
import './styles/main.css';

import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { checkTokenAndUpdate } from './auth';
import {trackGAPage} from "./store"
// import Pendo from './tracking/pendo';

function App() {
  const [ hasToken, setHasToken ] = useState(false);
  let location = useLocation();
  
  useEffect(() => {
    const fetchToken = async () => {
      return await checkTokenAndUpdate();
    }
    
    fetchToken().then((value) => {
      setHasToken(value)
    });

  }, [hasToken])
  
  useEffect(() => {
    // Pendo.setData();
    trackGAPage(location.pathname)

  }, [location])
  
  if (!hasToken){
    return <div/>;
  }

  return (
    <Routes>
      <Route path="/:url/*" element={<Home />} />
      <Route path="*" element={<Invalid />} />
    </Routes>
  );
}

export default App;
