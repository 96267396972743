import { useState, useLayoutEffect } from "react";
import LaunchIcon from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from "react-router-dom";

export default function Club(props) {
  const id = props.id;
  const name = props.name;
  const address = props.address;
  const fee = props.fee
  const link = props.link;
  const club_details = props.club_details;
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();

  const joinClub = () => {
    const attributes = {
      id: id,
      club_name: name,
      address: address,
      phone: props.phone,
      website: link,
      image_url: props.image_url,
      message: props.message
    };

    sessionStorage.setItem('entered_from_assoc_level', JSON.stringify(true));

    navigate(`club/${id}`, {
      state: {
        previous_page: 'club'
      }
    });
  }

  return (
    <div className="club-container row" style={{margin: '10px 0px'}}>
      <div className="info-container">
        <div className="row">
          <div className="title-container">
            <p className="club-title">{name}</p>
          </div>

            { (club_details || fee) && <button className="more-info" onClick={() => setShowInfo((prev) => !prev)}>
              more info
              {!showInfo && <ExpandMoreIcon className="expand-icon" />}
              {showInfo && <ExpandLessIcon className="expand-icon" />}
            </button>}
        </div>


        {address && <p className="row address">{address}</p>}
        <a href={`https://${link}`} target='_blank' className="row link">
          {link}
          {link && <LaunchIcon className="link-icon" />}
        </a>


        {showInfo &&
            <div>
              <div className="row">
                {fee && (
                      <table className="fees-table">
                        <tbody>
                        {fee.map((f) => (
                            <tr key={f.name}>
                              <th className="name">{f.name}:</th>
                              <th>${Number(f.amount).toFixed(2)}/yr</th>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                )}
              </div>
              <div className="row">
                <p className="info-message">{club_details}</p>
              </div>
            </div>
        }
      </div>

      <div className="btn-container">
        <button className="join-btn" onClick={props.preview ? null : joinClub}>Join</button>
      </div>
    </div>
  )
}

