import request from '../shared/request';

export default class GolferService {
  static updateGolfer(clubId, golferId, data) {
    return request({
      url: `/clubs/${clubId}/golfers/${golferId}.json`,
      method: 'PATCH',
      data
    })
  }

  static getCountriesAndStates() {
    return request({
      url: `/get_countries_and_states.json`,
      method: 'GET'
    })
  }

  static searchGolfer(data) {
    return request({
      url: `/search_golfer.json`,
      method: 'GET',
      params: data
    })
  }

  static searchGuardian(params) {
    return request({
      url: '/guardians.json',
      method: 'GET',
      params
    })
  }

  static showGolfer(club_id, golfer_id, include_club_membership_details = false, validate_membership = false, include_guardian_details = false) {
    return request({
      url: `/clubs/${club_id}/golfers/${golfer_id}.json?include_club_membership_details=${include_club_membership_details}&validate_membership=${validate_membership}&include_guardian_details=${include_guardian_details}`,
      method: 'GET'
    })
  }

  static createDigitalProfile(golfer_id, data) {
    return request({
      url: `/golfers/${golfer_id}/create_and_set_digital_profile.json`,
      method: 'POST',
      data
    })
  }

  static getGolfers(params) {
    return request({
      url: `/golfers.json`,
      params
    })
  }
}
