import { createSlice } from "@reduxjs/toolkit";

export const paymentSlice = createSlice({
  name: 'payment_slice',
  initialState: {
    payment: {
    }
  },
  reducers: {
    update: (state, action) => {
      state.payment = { ...state.payment, ...action.payload }
    },
    clear: (state) => {
      state.payment = {}
    }
  }
})

export const { update, clear } = paymentSlice.actions

export default paymentSlice.reducer
