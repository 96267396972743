import axios from 'axios'
import { onError, onSuccess } from './responseHandler'

import { expToken, checkTokenAndUpdate } from '../../auth';

const tokenName = `jr-token-${process.env.REACT_APP_BASENAME}`;
const tokenTypeName = `jr-token-type-${process.env.REACT_APP_BASENAME}`;


const client = (() => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });
})();

const request = function (options, store) {
  options.params = {
    ...options.params
  };

const currentToken = sessionStorage.getItem(tokenName);
  if (currentToken) {
    options.headers = {
      "Authorization": "Bearer " + currentToken
    };
  }

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

client.interceptors.request.use(config => {
  if (!(/auth_token/.test(config.url)) && expToken) {  
    const getToken = async () => {
      return await checkTokenAndUpdate();
    }
    getToken().then((value) =>{
      config.headers = {
        "Authorization": "Bearer " + value
      };
    });
    return config;
  } else {
    return config;
  }
});

client.interceptors.response.use(config => {
  return config;
}, err => {
  if (err && err.response && err.response.data && err.response.data.error && err.response.data.error === "Invalid token") {
    sessionStorage.removeItem(tokenName);
    
    // do not reload if the token is expired for show golfer (payment failed)
    if (!(/include_club_membership_details=true/.test(err.config.url))){
      window.location.replace(window.location.href);
    }
      
  } else {
    return Promise.reject(err);
  }
});



export default request;
