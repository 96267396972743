import React, { useState } from "react";

export const JoinPageContext = React.createContext();
export const JoinPageContextProvider = ({ children }) => {
  const [JoinPageData, set_JoinPageData] = useState({
    golf_association_details: {},
    golf_association_terms: {}
  });

  return (
    <JoinPageContext.Provider value={{ JoinPageData, set_JoinPageData }}>
      {children}
    </JoinPageContext.Provider>
  );
};