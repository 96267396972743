import ReactGA from 'react-ga4';

const ga_key = process.env.REACT_APP_GOOGLE_ANALYTICS;

// Initialize Google Analytics
if (ga_key)
  ReactGA.initialize(ga_key);

export function sendGAFormEvent(user_properties, form_name) {

  if (ga_key) {    
    ReactGA.gtag("set", "user_properties", user_properties);
    ReactGA.event({
      category: "FormName",
      action: "FormSubmissionCounter",
      label: form_name,
      nonInteraction: true
    });
  }
}
