import React, { Component } from 'react';
import Modal from "react-modal";
import { withKeyPress } from "../../shared/highOrderComponent/withKeyPress";
import CloseIcon from '@mui/icons-material/Close';

class ConfirmationModalTypeB extends Component {

  constructor(props) {
    super(props);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    this.props.onConfirmAction();
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  render() {
    return (
      <Modal
        isOpen={this.props.openModal}
        contentLabel="Modal"
        portalClassName="modal"
        overlayClassName="modal__overlay"
        className="modal__content"
        bodyOpenClassName="modal--is-open"
        htmlOpenClassName="prevent-scroll"
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
        ariaHideApp={false}
      >

        <div className={`modal__container ${this.props.wideClass ? this.props.wideClass : 'very-narrow'} confirmation type-b`}>
          <div className="modal__body">

            {this.props.modalIcon && <div className="modal-head__icon">
              <i><this.props.modalIcon
                className={'material-icons-outlined'}
              /></i>
            </div>
            }

            {this.props.hideTitle !== true && <h2 className="modal__title">{this.props.question}</h2>}
            <button className="modal__close" onClick={() => this.props.closeModal()}><i className="material-icons-outlined"><CloseIcon /></i></button>

            {this.props.bodyQuestion && <div><p className="medium align-center">{this.props.bodyQuestion}</p></div>}

            {(this.props.infoText && this.props.infoText.length > 0) &&
              <div style={{ marginTop: '24px' }}>
                {this.props.infoText.map((text, index) => {
                  return (<p key={index} className="medium align-center">{text}</p>);
                })}
              </div>
            }
            
            {(this.props.message) &&
              <div>
                <p className="align-center font-size-18">{this.props.message}</p>
              </div>
            }

            {this.props.note && <div><span className={"bold"}>Note: </span><span className="gray">{this.props.note}</span></div>}
            {this.props.error && <div><span>{this.props.error}</span></div>}

            <div className="row">
              {this.props.cancelLabel && <div className="col">
                <button type="button" disabled={this.props.loading} onClick={() => {
                  this.props.onCancelAction()
                }} className={this.props.buttonClassNameCancel ? `btn fill gray ${this.props.buttonClassNameCancel}` : "btn fill gray"}>{this.props.cancelLabel}</button>
              </div>
              }
              <div className="col">
                <button
                  type="submit"
                  ref={'submit'}
                  disabled={this.props.loading || this.props.error}
                  onClick={this.onConfirm}
                  className={this.props.mergeContext && this.props.loading ? `btn smaller loading fill blue center_horizontal_margin ${this.props.buttonClassName || ''}` : `btn smaller fill blue center_horizontal_margin ${this.props.buttonClassName || ''}`}>
                  {(!this.props.mergeContext || !this.props.loading) && this.props.confirmLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default withKeyPress(ConfirmationModalTypeB);
