import request from '../shared/request';

export default class JoinPageService {
  static getJoinPageData(url, data) {
    return request({
      url: `/join_pages/${url}.json`,
      method: 'GET',
      params: data
    })
  }

  static getCountriesAndStates() {
    return request({
      url: `/get_countries_and_states.json`,
      method: 'GET'
    })
  }
  
  static getJoinClubPages(url, data) {
    return request({
      url: `/join_pages/${url}/clubs.json`,
      method: 'GET',
      params: data
    })
  }

  static getClubJoinPageInformation(url, club_id, data) {
    return request({
      url: `/join_pages/${url}/clubs/${club_id}.json`,
      method: 'GET',
      params: data
    })
  }
  
  static getAuthToken() {
    return request({
      url: `/join_pages/auth_token.json`,
      method: 'GET'
    })
  }

  static sendVerificationCode(data) {
    return request({
      url: `/join_pages/send_verification_code.json`,
      method: 'POST',
      data
    })
  }

  static verifyCode(data) {
    return request({
      url: `/join_pages/verify_code.json`,
      method: 'POST',
      data
    })
  }
}
