import { createSlice } from "@reduxjs/toolkit";

export const loggedInUserSlice = createSlice({
  name: 'logged_in_user',
  initialState: {
    logged_in_user_data: {
      email: '',
      is_guardian: false
    }
  },
  reducers: {
    update_logged_in_user: (state, action) => {
      state.logged_in_user_data = { ...state.logged_in_user_data, ...action.payload }
    },
    clear_logged_in_user: (state) => {
      state.logged_in_user_data = {
        email: '',
        is_guardian: false
      }
    }
  }
})

export const { update_logged_in_user, clear_logged_in_user } = loggedInUserSlice.actions

export default loggedInUserSlice.reducer
