import { Fragment, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { JoinPageContext } from "../../context/join_page_context";
import FirstPage from "../FirstPage";
import InactiveJoinPage from "../InactiveJoinPage";

function AssociationHome() {
  const { JoinPageData } = useContext(JoinPageContext)
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--preferred-color",
      JoinPageData.golf_association_details && JoinPageData.golf_association_details.color ? `#${JoinPageData.golf_association_details.color}` : "black"
    );
  }, [JoinPageData]);
    
  const parsePhone = (phone) => {
    if (phone !== null && phone !== undefined && phone !== ""){
      const val = phone.toString();
      if (val.length === 10)
        phone = `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`;
    }
    
    return phone;
  }

  return (
    <Fragment>
      <div>
        <div className='logo-image-div'>
          {!JoinPageData.hide_logo && JoinPageData.header_image && <img className='logo-image' src={JoinPageData.header_image} alt={"Link Header"} />}
        </div>
      </div>
      <Fragment>
        <Routes>
          <Route path="/preview" element={<FirstPage preview={true} />} />
          <Route path="/" element={<FirstPage preview={false} />} />
          <Route path="inactive_join_page" element={<InactiveJoinPage />} />
        </Routes>
      </Fragment>
      <footer>
        <div className="logo-image-div grey-bottom-line">
          <hr className="gray-bar"/>
          
          <div className="small_padding info-box-association">
            <p className="text-align-center bold">
              {JoinPageData.golf_association_details &&
                JoinPageData.golf_association_details.name}
            </p>

            <p className="text-align-center">
              {JoinPageData.golf_association_details &&
                JoinPageData.golf_association_details.street_1}
            </p>
            <p className="text-align-center">
              {JoinPageData.golf_association_details &&
                JoinPageData.golf_association_details.street_2}
            </p>
            <p className="text-align-center">
              {JoinPageData.golf_association_details &&
                `${JoinPageData.golf_association_details.city}, ${JoinPageData.golf_association_details.state} ${JoinPageData.golf_association_details.zip_code}`}
            </p>
            <p className="text-align-center">
              {JoinPageData.golf_association_details && (
                  <a href={`mailto:${JoinPageData.golf_association_details.email}`}>
                    {JoinPageData.golf_association_details.email}
                  </a>
              )}
            </p>

            <p className="text-align-center">
              {JoinPageData.golf_association_details &&
                  parsePhone(JoinPageData.golf_association_details.phone)}

              {JoinPageData.golf_association_details &&
                  JoinPageData.golf_association_details.link && (
                      <span> |{' '}
                        <a href={`http://${JoinPageData.golf_association_details.link}`} target="_blank" rel="noopener noreferrer">
                          {JoinPageData.golf_association_details.link}
                        </a>
                      </span>
                  )}
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default AssociationHome;
