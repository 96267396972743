export default function CardUpdated() {
  return (
    <div className='logo-image-div'>
      <strong>
        <p style={{ fontSize: "xx-large", textAlign: 'center', padding: '100px 0' }}>
          Payment method updated successfully!
        </p>
      </strong>
    </div>
  )
}