export const isPresent = (value) => {  
  let presentValue = false; // checks values like numbers / texts// checks arrays
  const isArray = Array.isArray(value);
  
  if (!isArray && value !== undefined && value !== null && value !== '')
    presentValue = true;

  if (isArray && value.length > 0)
    presentValue = true;  
    
  return presentValue;  
};
