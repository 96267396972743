import React, { Fragment, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { JoinPageContext } from '../context/join_page_context';
import { update_addons } from '../features/addons/addonsSlice';
import JoinPageService from '../services/api/join_page';
import initializeCollectJs from '../services/shared/collect';
import Association from './association'
import Club from './club/index'
import moment from "moment";

function Home() {
  const { set_JoinPageData } = useContext(JoinPageContext)
  const { url } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const preview = location.pathname.includes('/preview');
    const data = {
      'preview': preview
    }

    JoinPageService.getJoinPageData(url, data).then((res) => {
      if ((res.join_page.is_enabled === true && res.join_page.has_memberships) || preview) {
        const data = { ...res.join_page }
        dispatch(update_addons(data.add_on_charges))
        delete data.add_on_charges
        set_JoinPageData(data)
        initializeCollectJs(data.payment_gateway_settings.tokenization_key, () => {console.log("publick key has been set properly")})  
      } else {
        set_JoinPageData(res.join_page);
        navigate('inactive_join_page');
      }
    }).catch(() => {
      navigate('/')
    })
  }, [])

  return (
    <Fragment>
      <Routes>
        <Route path="/*" element={<Association />} />
        <Route path="/club/:club_id/*" element={<Club />} />
      </Routes>
      <footer>
        <div className='footer__container'>
          <div>
            <a href={"https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-privacy-policy.html"}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <span>&nbsp; | &nbsp;</span>
            <a href={"https://www.usga.org/content/usga/home-page/Handicap-ghin/ghin-terms-of-service.html"}
              target="_blank"
              rel="noreferrer">
              Terms of Service
            </a>
          </div>

          <br />

          <p>&copy; {moment().format('YYYY')} United States Golf Association. All Rights Reserved.</p>
        </div>
      </footer>
    </Fragment>
  );
}

export default Home;
