import React, {useEffect, useState} from "react";

export default function RadioPicker(props) {

  const [value, setValue] = useState(props.value);

  const handleChangeValue = (value) => {
    setValue(value);
    props.onChange({
      target: {
        name: 'gender',
        value: value
      }
    });
  }

  useEffect(()=>{
    setValue(props.value)
  },[props.value, setValue])

  return (
    <div className={'create_input ' + props.size}>
      <div className='row'>
        <div className='col is-full'>
          <label className='black' htmlFor={props.id}>
            {props.label}
          </label>
          <div style={{ display: 'flex' }}>
            <div
              className={`radio_box checkbox ${value === 'M' ? 'clicked' : ''}`}
              onClick={() => handleChangeValue('M')}
            >
              <span className={`label ${(value === 'M' ? 'clicked' : '')}`}>Male</span>
            </div>
            <div
              style={{ borderLeft: 'none' }}
              className={`radio_box checkbox ${value === 'F' ? 'clicked' : ''}`}
              onClick={() => handleChangeValue('F')}
            >
              <span className={`label ${value === 'F' ? 'clicked' : ''}`}>Female</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
