export const processErrorMessage = (errors) => {
	if (typeof errors === 'string') {
		return errors;
	}
	let errorMessage = '';
	if (typeof errors === 'object') {
		Object.keys(errors).forEach((key) => {
			if (Array.isArray(errors[key])) {
				errorMessage = errors[key][0]
			} else {
				errorMessage = errors[key];
			}
		});
	}

	return errorMessage || null;
};
