import React, {Fragment, useCallback, useEffect, useMemo, useState, useContext} from 'react';
import InputField from '../../inputs/InputField';
import {debounce} from "@mui/material";
import GolferService from "../../services/api/golfer";
import * as Yup from 'yup';
import {update_guardian} from '../../features/golfer/golferSlice';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import moment from "moment/moment";
import * as EmailValidator from "email-validator";
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {processErrorMessage} from "../../shared/errorHelper";
import { sendGAFormEvent } from "../../tracking/ga";
import { JoinPageContext } from '../../context/join_page_context';


function Guardian() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('')
  const { club_id, url } = useParams();
  
  const { JoinPageData } = useContext(JoinPageContext);
  const store_guardian_data = useSelector(state => state.golfer.guardian)
  const store_golfer_data = useSelector(state => state.golfer.golfer)
  const store_address_data = useSelector(state => state.golfer.address)
  const [guardianExistingGhin, setGuardianExistingGhin] = useState(
    store_guardian_data.hasOwnProperty("id")
      ? store_guardian_data.id !== null
      : true
  );
  const [golferGuardianDetails, setGolferGuardianDetails] = useState({
    id: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    relationship: '',
    other_relationship: ''
  });
  const [nonGolferGuardianDetails, setNonGolferGuardianDetails] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    relationship: '',
    other_relationship: ''
  });
  const [oldGolferGuardianId, setOldGolferGuardianId] = useState('');
  const [guardianFoundByEmail, setGuardianFoundByEmail] = useState(false);
  const [hasSearchedEmail, setHasSearchedEmail] = useState(false);
  const [golferNotFound, setGolferNotFound] = useState(false);

  const [valid, set_valid] = useState(false)
  const ghinNumberValidationSchema = {
    'Guardian GHIN Number': Yup.string().test('integer-shape', 'Guardian GHIN Number must be a number', function (value) {
      return (value === '' || /^\d+$/.test(value));
    }).required('Guardian GHIN Number is a required field and must be numeric.')
  };
  const emailValidationSchema = {
    'Email Address': Yup.string().required().test('valid-email', 'Invalid Email Address', (value) => {
      return EmailValidator.validate(value);
    })
  };
  const phoneValidationSchema = {'Phone': Yup.string().required()};
  const firstNameValidationSchema = {'First Name': Yup.string().required()};
  const lastNameValidationSchema = {'Last Name': Yup.string().required()};

  const ghinNumberValidationObject = Yup.object({...ghinNumberValidationSchema})
  const validationSchema = Yup.object({
    ...emailValidationSchema,
    ...phoneValidationSchema,
    ...firstNameValidationSchema,
    ...lastNameValidationSchema
  })

  useEffect(() => {
    validationSchema.isValid({
      'Email Address': nonGolferGuardianDetails.email,
      'Phone': nonGolferGuardianDetails.phone_number,
      'First Name': nonGolferGuardianDetails.first_name,
      'Last Name': nonGolferGuardianDetails.last_name
    }).then(res => {
      set_valid(res)
    })
  }, [nonGolferGuardianDetails.email, nonGolferGuardianDetails.phone_number, nonGolferGuardianDetails.first_name, nonGolferGuardianDetails.last_name, validationSchema])

  useEffect(() => {
    if (store_guardian_data?.id)
      setGolferGuardianDetails({
        id: store_guardian_data?.id,
        first_name: store_guardian_data.first_name,
        middle_name: store_guardian_data.middle_name,
        last_name: store_guardian_data.last_name,
        email: store_guardian_data.email,
        relationship: store_guardian_data.relationship,
        other_relationship: store_guardian_data?.other_relationship
      });
    else
      setNonGolferGuardianDetails({
        id: store_guardian_data?.id,
        first_name: store_guardian_data.first_name,
        middle_name: store_guardian_data.middle_name,
        last_name: store_guardian_data.last_name,
        email: store_guardian_data.email,
        phone_number: store_guardian_data.phone_number,
        relationship: store_guardian_data.relationship,
        other_relationship: store_guardian_data?.other_relationship
      });
  }, [store_guardian_data]);


  const handleChange = (event) => {
    setGuardianExistingGhin(event.target.value === 'true');
  }

  const handleGolferFormValues = (event) => {
    setGolferGuardianDetails({...golferGuardianDetails, [event.target.name]: event.target.value})
  }

  const handleNonGolferFormValues = (event) => {
    setNonGolferGuardianDetails({...nonGolferGuardianDetails, [event.target.name]: event.target.value})
  }

  const searchGuardian = (ghinNumber) => {
    ghinNumberValidationObject.isValid({'Guardian GHIN Number': ghinNumber,}).then(valid => {
      if (valid) {
        GolferService.searchGolfer({'golfer_id': ghinNumber})
          .then(res => {
            const golfer = res.golfer
            setGolferGuardianDetails({
              ...golferGuardianDetails,
              id: ghinNumber,
              first_name: golfer["first_name"],
              middle_name: golfer["middle_name"],
              last_name: golfer["last_name"],
              email: golfer["email"],
            });
            setOldGolferGuardianId(ghinNumber);
            setGolferNotFound(false);
          })
          .catch(err => {
            console.log(err);
            setOldGolferGuardianId(ghinNumber);
            setGolferGuardianDetails({
              ...golferGuardianDetails,
              id: null,
              first_name: '',
              middle_name: '',
              last_name: '',
              email: '',
            });
            setGolferNotFound(true);
          })
      } else {
        setOldGolferGuardianId(ghinNumber);
        setGolferGuardianDetails({
          ...golferGuardianDetails,
          id: null,
          first_name: '',
          middle_name: '',
          last_name: '',
          email: '',
        });
        setGolferNotFound(false);
      }
    })
  }

  const debouncedSearchGuardian = useMemo(
    () => debounce(searchGuardian, 500)
    , [searchGuardian]
  );

  const handleGhinNumber = (event) => {
    const ghinNumber = event.target.value;
    debouncedSearchGuardian(ghinNumber);
  }

  const searchEmail = (email) => {
    const params = {
      page: 1,
      per_page: 1,
      unique_guardians: true,
      global_search: false,
      only_non_golfing_guardians: true,
      search: email
    }

    if (email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      GolferService.searchGuardian(params).then(res => {
        const guardians = res.guardians[0]
        if (!guardians) {
          if(guardianFoundByEmail)
            setNonGolferGuardianDetails({
              ...nonGolferGuardianDetails,
              email: email,
              phone_number: '',
              first_name: '',
              middle_name: '',
              last_name: ''
            });
          setGuardianFoundByEmail(false);
          return
        }
        setGuardianFoundByEmail(true);
        setNonGolferGuardianDetails({
          id: null,
          email: guardians.email,
          phone_number: guardians.phone_number,
          first_name: guardians.first_name,
          middle_name: guardians.middle_name,
          last_name: guardians.last_name
        })
      })
    }
  }

  const searchEmailDebounce = useMemo(
    () => debounce(searchEmail, 500)
    , [searchEmail]
  );

  const handleEmail = (event) => {
    const email = event.target.value;
    setNonGolferGuardianDetails({...nonGolferGuardianDetails, ["email"]: email})
    searchEmailDebounce(email);
  }

  useEffect(() => {
    if (!hasSearchedEmail && nonGolferGuardianDetails.email) {
      searchEmail(nonGolferGuardianDetails.email);
      setHasSearchedEmail(true);
    }
  }, [nonGolferGuardianDetails.email, hasSearchedEmail]);

  const isSubmittable = useCallback(() => {
    if (guardianExistingGhin) { // Guardian has an existing GHIN number.
      const { id, first_name, last_name, email, relationship, other_relationship } = golferGuardianDetails;
      if (id !== null && first_name && last_name && email && relationship) {
        if (relationship !== "Other" || other_relationship)
          return true;
      }
    } else {
      if (guardianFoundByEmail) { // Guardian not found by GHIN number but found by email using searchGuardian API.
        const { first_name, last_name, email, relationship, other_relationship } = nonGolferGuardianDetails;
        if (email && first_name && last_name && relationship) {
          if (relationship !== 'Other' || other_relationship)
            return true;
        }
      } else { // Guardian not found by GHIN number and not found by email using searchGuardian API.
        const { relationship, other_relationship } = nonGolferGuardianDetails;
        if (valid && relationship) {
          if (relationship !== 'Other' || other_relationship)
            return true;
        }
      }
    }
    return false;
  }, [golferGuardianDetails, nonGolferGuardianDetails, valid, guardianExistingGhin, guardianFoundByEmail]);


  const saveData = () => {
    
    
    if (store_golfer_data.id) { //existing golfer case
      const user_properties = {
        club_id: club_id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: store_golfer_data.id
      }
      sendGAFormEvent(user_properties, "Edit Guardian Details Form")
    } else {
      const user_properties = {
        club_id: club_id.toString(),
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: "-"
      }
      sendGAFormEvent(user_properties, "Add Guardian Details Form")
    }

    if (store_golfer_data.id) { //existing golfer case      
      const address = {...store_address_data}

      const golfer = {
        first_name: store_golfer_data.first_name,
        last_name: store_golfer_data.last_name,
        email: store_golfer_data.email,
        phone_number: store_golfer_data.phone_number,
        date_of_birth: moment(store_golfer_data.date_of_birth).format('YYYY/MM/DD'),
        gender: store_golfer_data.gender,
      }

      const age = moment().diff(moment(store_golfer_data.date_of_birth).format('YYYY/MM/DD'), 'years');
      if (age < 13) {
        golfer['email'] = ''
        golfer['assignGuardian'] = true
        if (!guardianExistingGhin) {
          golfer['golfer'] = {hasGhinNumber: 'false'}
          golfer['guardian'] = {
            email: nonGolferGuardianDetails.email,
            relationship: nonGolferGuardianDetails.relationship
          }
        }
      }

      let guardian = {}
      if (guardianExistingGhin)
        guardian = {golfer_id: golferGuardianDetails.id, relationship: golferGuardianDetails.relationship}
      else
        guardian = {...nonGolferGuardianDetails}

      GolferService.updateGolfer(
        store_golfer_data.primary_club_id,
        store_golfer_data.id,
        {
          golfer_club: null,
          golfer,
          address,
          guardian,
          jr_join_page_url: `/${url}/club/${club_id}`
        })
        .then(res => {
          dispatch(update_guardian({...golferGuardianDetails, phone_number: null}))
          navigate(`../golfer_details`);
        })
        .catch(e => {
          const errorMessage = processErrorMessage(e.data.errors);
          setError(errorMessage);
          console.log(e);
        })
    } else { // new golfer case    
      if (guardianExistingGhin)
        dispatch(update_guardian({...golferGuardianDetails, phone_number: null}));
      else
        dispatch(update_guardian({...nonGolferGuardianDetails, id: null}));
      navigate(`../membership`, {state: {previous_page: 'guardian'}})
    }
  }
  
  const parsePhone = (phone) => {
    if (phone !== null && phone !== undefined && phone !== ""){
      const val = phone.toString();
      if (val.length === 10)
        phone = `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`;
    }
    
    return phone;
  }
  
  return (
    <Fragment>

      <div className='guardian_page'>
        <div className='box-panel center'>
          <div className='container minw-auto w-100'>

            <div style={{textAlign: 'left', fontSize: '14px', lineHeight: '1.2em'}}>
              <span style={{fontWeight: 700}}>Note:</span> The birthday provided indicates this golfer is a minor.
              A guardian is required for golfers less than 13 years of age. The guardian must be at least 18 years of
              age.
            </div>

            <div style={{marginTop: '25px'}} className='left'>
              <span className='header-text'>
                <strong>Does the guardian have a GHIN Number?</strong>
              </span>
              <div style={{ marginTop: '10px' }} onChange={handleChange}>
                <input type="radio" defaultChecked={guardianExistingGhin} value={true}
                       name="guardian_existing_ghin"/> Yes
                <input style={{marginLeft: '20px'}} type="radio" defaultChecked={!guardianExistingGhin} value={false}
                       name="guardian_existing_ghin"/> No
              </div>
            </div>

            {
              guardianExistingGhin ?
                <>
                  <div className={'w-100'}>
                    <div className="big left">
                      <div className="row">
                        <div className="col is-full">
                          <InputField
                            style={{width: '100%'}}
                            className={"w-100"}
                            size='big'
                            id='ghin_number'
                            type='number'
                            label='Guardian GHIN Number'
                            value={golferGuardianDetails["id"] ? golferGuardianDetails["id"] : oldGolferGuardianId}
                            validationSchema={Yup.object(ghinNumberValidationSchema)}
                            onChange={handleGhinNumber}
                            autoComplete={'off'}
                            clearError={golferNotFound}
                            onKeyDown={(e) =>["e", "E", "+", "-", '.', ','].includes(e.key) && e.preventDefault()}
                            requiredIcon
                          />
                          {golferNotFound && (
                            <span className="error-text big" style={{ marginTop: 0 }}>No results found, please change your search criteria.</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {golferGuardianDetails["id"] ? (
                      <>
                        <div className={'w-100'}>
                          <div className='create_input left top-margin'>
                            <div className="row">
                              <div className="col is-1-of-9">
                                <strong className='black' htmlFor="name">
                                  NAME
                                </strong>
                              </div>
                              <div className="col center_vertical_margin">
                                <span>{golferGuardianDetails["id"] !== null ? golferGuardianDetails["first_name"] + " " + golferGuardianDetails["middle_name"] + " " + golferGuardianDetails["last_name"] : '-'}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'w-100'}>
                          <div className='create_input left no_top_margin'>
                            <div className="row medium-top-margin">
                              <div className="col is-1-of-9">
                                <strong className='black' htmlFor="email">
                                  EMAIL
                                </strong>    
                              </div>
                              <div className="col center_vertical_margin">
                              {
                                golferGuardianDetails['email']
                                  ?
                                  <span>
                                    {golferGuardianDetails["email"]}
                                  </span>
                                  :
                                  <span className="error-text no_margin">
                                    There is no email address associated with the Guardian GHIN Number entered. This field is required and must be updated prior to registering this golfer. 
                                    To add an email address, please contact the {JoinPageData.golf_association_details.name} at {JoinPageData.golf_association_details.email} or {parsePhone(JoinPageData.golf_association_details.phone)}.
                                  </span>
                              }
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'w-100'}>
                          <div className="create_input big left top-margin">
                            <div className="row">
                              <div className="col is-full">
                                <label className="black" htmlFor="relationship">
                                  RELATIONSHIP TO MINOR <i className="light_red">*</i>
                                </label>
                                <select
                                  className={'w-100'}
                                  id="relationship"
                                  name="relationship"
                                  onChange={handleGolferFormValues}
                                  value={golferGuardianDetails.relationship}
                                >
                                  <option value=''>Select...</option>
                                  <option value="Father">Father</option>
                                  <option value="Mother">Mother</option>
                                  <option value="Legal Guardian">Legal Guardian</option>
                                  <option value="Coach">Coach</option>
                                  <option value="Other">Other</option>
                                </select>
                                <span className="error">{"" || ""}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          golferGuardianDetails.relationship === 'Other' &&
                          <div className={'w-100'}>
                            <div className="create_input big left">
                              <div className="row">
                                <div className="col is-full">
                                  <InputField
                                    style={{width: '100%'}}
                                    className={"w-100"}
                                    size='medium'
                                    id='other_relationship'
                                    label='WHAT IS THE RELATIONSHIP?'
                                    value={golferGuardianDetails.other_relationship}
                                    onChange={handleGolferFormValues}
                                    autoComplete={'off'}
                                    requiredIcon
                                  />
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    )
                    :
                    null
                  }
                </>
                :
                <>
                  {
                    guardianFoundByEmail ?
                      <>
                        <div className={'w-100'}>
                          <div className="create_input big left">
                            <div className="row">
                              <div className="col is-full">
                                <InputField
                                  style={{width: '100%'}}
                                  className={"w-100"}
                                  size='medium'
                                  id='email'
                                  label='Email Address'
                                  value={nonGolferGuardianDetails.email}
                                  validationSchema={Yup.object(emailValidationSchema)}
                                  onChange={handleEmail}
                                  autoComplete={'off'}
                                  requiredIcon
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'w-100'}>
                          <div className='create_input left'>
                            <div className="row">
                              <div className="col is-full">
                                <label className='black' htmlFor="name">
                                  NAME
                                </label>
                                <span>{nonGolferGuardianDetails["first_name"] + " " + nonGolferGuardianDetails["middle_name"] + " " + nonGolferGuardianDetails["last_name"]}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={'w-100'}>
                          <div className="create_input big left no_top_margin">
                            <div className="row">
                              <div className="col is-full">
                                <label className="black" htmlFor="relationship">
                                  RELATIONSHIP TO MINOR <i className="light_red">*</i>
                                </label>
                                <select
                                  className={'w-100'}
                                  defaultValue=''
                                  id='relationship'
                                  name='relationship'
                                  onChange={handleNonGolferFormValues}
                                >
                                  <option value=''>Select...</option>
                                  <option value="Father">Father</option>
                                  <option value="Mother">Mother</option>
                                  <option value="Legal Guardian">Legal Guardian</option>
                                  <option value="Coach">Coach</option>
                                  <option value="Other">Other</option>
                                </select>
                                <span className="error">{"" || ""}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          nonGolferGuardianDetails.relationship === 'Other' &&
                          <div className={'w-100'}>
                            <div className="create_input big left">
                              <div className="row">
                                <div className="col is-full">
                                  <InputField
                                    style={{width: '100%'}}
                                    className={"w-100"}
                                    size='medium'
                                    id='other_relationship'
                                    label='WHAT IS THE RELATIONSHIP?'
                                    value={nonGolferGuardianDetails.other_relationship}
                                    onChange={handleNonGolferFormValues}
                                    autoComplete={'off'}
                                    requiredIcon
                                  />
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </>
                      :
                      <>
                        <div className='row with-no-bottom-margin w-100'>
                          <div className='col is-1-of-2'>
                            <div className="create_input no_top_margin">
                              <div className="row">
                                <div className="col is-full">
                                  <InputField
                                    className='mw-100'
                                    size='large'
                                    id='email'
                                    label='Email Address'
                                    value={nonGolferGuardianDetails.email}
                                    validationSchema={Yup.object(emailValidationSchema)}
                                    onChange={handleEmail}
                                    autoComplete={'off'}
                                    requiredIcon
                                  />
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col is-1-of-2'>
                            <div className="create_input no_top_margin">
                              <div className="row">
                                <div className="col is-full">
                                  <InputField
                                    className='mw-100'
                                    size='large'
                                    id='phone_number'
                                    label='Phone'
                                    value={nonGolferGuardianDetails.phone_number}
                                    validationSchema={Yup.object(phoneValidationSchema)}
                                    onChange={handleNonGolferFormValues}
                                    autoComplete={'off'}
                                    requiredIcon
                                  />
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row with-no-bottom-margin w-100'>
                          <div className='col is-1-of-2'>
                            <div className="create_input no_top_margin">
                              <div className="row">
                                <div className="col is-full">
                                  <InputField
                                    className='mw-100'
                                    size='large'
                                    id='first_name'
                                    label='First Name'
                                    value={nonGolferGuardianDetails.first_name}
                                    validationSchema={Yup.object(firstNameValidationSchema)}
                                    onChange={handleNonGolferFormValues}
                                    autoComplete={'off'}
                                    requiredIcon
                                    compact
                                  />
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col is-1-of-2'>
                            <div className="create_input no_top_margin">
                              <div className="row">
                                <div className="col is-full">
                                  <InputField
                                    className='mw-100'
                                    size='large'
                                    id='last_name'
                                    label='Last Name'
                                    value={nonGolferGuardianDetails.last_name}
                                    validationSchema={Yup.object(lastNameValidationSchema)}
                                    onChange={handleNonGolferFormValues}
                                    autoComplete={'off'}
                                    requiredIcon
                                    compact
                                  />
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row w-100 no_bottom_margin'>
                          <div className='col is-1-of-2'>
                            <div className="create_input no_top_margin">
                              <div className="row">
                                <div className="col is-full">
                                  <label className="black label-fix" htmlFor="relationship">
                                    RELATIONSHIP <i className="light_red">*</i>
                                  </label>
                                  <select
                                    defaultValue=''
                                    id='relationship'
                                    name='relationship'
                                    onChange={handleNonGolferFormValues}
                                    className={'mw-100'}
                                  >
                                    <option value=''>Select...</option>
                                    <option value="Father">Father</option>
                                    <option value="Mother">Mother</option>
                                    <option value="Legal Guardian">Legal Guardian</option>
                                    <option value="Coach">Coach</option>
                                    <option value="Other">Other</option>
                                  </select>
                                  <span className="error">{"" || ""}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            nonGolferGuardianDetails.relationship === 'Other' &&
                            <div className='col is-1-of-2'>
                              <div className="create_input">
                                <div className="row">
                                  <div className="col is-full">
                                    <label className="black" htmlFor="other_relationship">
                                      WHAT IS THE RELATIONSHIP? <i className="light_red">*</i>
                                    </label>
                                    <input
                                      type="text"
                                      id="other_relationship"
                                      name="other_relationship"
                                      value={nonGolferGuardianDetails.other_relationship}
                                      onChange={handleNonGolferFormValues}
                                      className={'mw-100'}
                                    />
                                    <span className="error">{"" || ""}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </>
                  }
                </>
            }
            <div className="row">
              <div className="col is-1-of-2">
                <button
                  className="btn x-smaller fill gray top-margin"
                  type={"button"}
                  onClick={() => { navigate('../address'); }}
                >
                  Go Back
                </button>
              </div>
              <div className="col is-1-of-2">
                <button
                  className="btn x-smaller fill cardinal top-margin"
                  type={"submit"}
                  onClick={() => saveData()}
                  disabled={!isSubmittable()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={error.length > 0}
        onConfirmAction={() => { setError('') }}
        onCancelAction={() => { setError('') }}
        closeModal={() => { setError('') }}
        question={error}
        confirmLabel={"Confirm"}
        modalIcon={CheckCircleOutlineIcon}
      />

    </Fragment>
  );
}

export default Guardian;
