import { createSlice } from "@reduxjs/toolkit";

export const clubSlice = createSlice({
  name: 'club',
  initialState: {
    club_data: {
    }
  },
  reducers: {
    update: (state, action) => {
      state.club_data = { ...state.club_data, ...action.payload }
    },
    clear_club: (state) => {
      state.club_data = {}
    }
  }
})

export const { update, clear_club } = clubSlice.actions

export default clubSlice.reducer
