import { combineReducers, configureStore } from '@reduxjs/toolkit'
import clubSlice from './features/club/clubSlice'
import golferSlice from './features/golfer/golferSlice'
import addonsSlice from './features/addons/addonsSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import paymentSlice from './features/payment/paymentSlice'
import membershipSlice from './features/membership/membershipSlice'
import loggedInUserSlice from './features/logged_in_user/loggedInUserSlice'
import ReactGA from 'react-ga4';

const ga_key = process.env.REACT_APP_GOOGLE_ANALYTICS;

if (ga_key)
  ReactGA.initialize(ga_key);

export const trackGAPage = (page) => {
  if (ga_key)
	   ReactGA.ga('send', 'pageview', page);
};

const reducers = combineReducers({
  addons: addonsSlice,
  club: clubSlice,
  golfer: golferSlice,
  payment: paymentSlice,
  membership: membershipSlice,
  loggedInUserSlice: loggedInUserSlice
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})
