export default async function ainitializeCollectJs(tokenizationKey, callback) {
  const existingScript = document.getElementById('collect_js');
  
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://secure.networkmerchants.com/token/Collect.js';
    script.id = 'collect_js';
    script.setAttribute("data-tokenization-key", tokenizationKey);
    script.async = true;
    document.body.appendChild(script);
    script.onload = async () => { 
      if (callback){
        await asyncTimeout(1000);
        callback();
      }  
    };
  }
  if (existingScript && callback){
    await asyncTimeout(1000);
    callback();
  }
} 

const asyncTimeout = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
