import JoinPageService from "./services/api/join_page";

import jwt_decode from 'jwt-decode';

const tokenName = `jr-token-${process.env.REACT_APP_BASENAME}`;
const tokenTypeName = `jr-token-type-${process.env.REACT_APP_BASENAME}`;
const sleep = ms => new Promise(res => setTimeout(res, ms));

export const setTokenCookie = (token, type) => {
	sessionStorage.setItem(tokenName, token);
	sessionStorage.setItem(tokenTypeName, type);
}

export const removeTokenCookie = (token, type) => {
	sessionStorage.removeItem(tokenName);
	sessionStorage.removeItem(tokenTypeName);
}

export const getNewToken = async() => {
	const response = await JoinPageService.getAuthToken();
	setTokenCookie(response.token, 'public');
	console.log("The public token has been generated");
	return response.token;
}

export const expToken = () => {
	const tokenDateTime = expTokenDate();
	const currentDatetime = Date.now();
	const exp = tokenDateTime ? Math.floor((currentDatetime - tokenDateTime) / (1000*60*60)) > -2 : true;
	return exp;
}

export const expTokenDate = () => {	
	let token = sessionStorage.getItem(tokenName);
	if (token) {
		try {
			let data = jwt_decode(token);
			return data.exp*1000;
		}
		catch {
			return null;
		}
	} 
	return null;
}

export const checkTokenAndUpdate = async () => {
	let token = sessionStorage.getItem(tokenName);
	const tokenDateTime = await expTokenDate();
	const tokenType = sessionStorage.getItem(tokenTypeName);
	
	const currentDatetime = Date.now();
	const exp = tokenDateTime ? Math.floor((currentDatetime - tokenDateTime) / (1000*60*60)) > -2 : true;
	const isGolfer = tokenType === 'golfer';
	const autoRenewFailed = sessionStorage.getItem('auto_renew_failed') === 'true'
	const updateCardDetails = sessionStorage.getItem('update_card_details') === 'true'

	if (exp && !isGolfer)  {
		token = await getNewToken();
	}

	if (exp && isGolfer) {
		const href = window.location.href

		if (updateCardDetails || autoRenewFailed) {
      removeTokenCookie();
			sessionStorage.removeItem('auto_renew_failed');
			sessionStorage.removeItem('update_card_details');
			await sleep(500);
      // Redirect to /unavailable because the update card details link expired
      const clubPath = href.split('/').slice(0, 6).join('/');
      window.location.replace(clubPath + '/verify_golfer');
		} else {
			removeTokenCookie();
			await sleep(500);
			const clubPath = href.split('/').slice(0, 6).join('/');
			window.location.replace(clubPath);
		}

	}
	
	return token;
}
