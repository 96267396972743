import request from '../shared/request';

export default class PaymentService {
  static pay(data) {
    return request({
      url: `/transactions.json`,
      method: 'POST',
      data
    })
  }

  static updateCard(data) {
    return request({
      url: `/transactions/update_card.json`,
      method: 'POST',
      data
    })
  }

  static applyCode(association_id, data) {
    return request({
      url: `/associations/${association_id}/promotional_codes/apply_code.json`,
      method: 'POST',
      data
    })
  }

  static autoAppliedCodes(association_id, data) {
    return request({
      url: `/associations/${association_id}/promotional_codes/auto_applied_codes.json`,
      method: 'POST',
      data
    })
  }
}
