import React from "react";

export default function Select(props) {

  return (
    <div style={props.style} className={'create_input ' + props.size}>
      <div className='row'>
        <div className='col is-full'>
          <label className='black' htmlFor={props.id}>
            {props.label}
          </label>
          <select
            id={props.id}
            defaultValue={props.defaultValue}
            name={props.id}
            onChange={props.onChange}
            value={props.value}
            className={props.className}
          >
            {
              props.options.map((option, index) => {
                return <option key={index}>{option[props.selectType] || option.code}</option>
              })
            }
          </select>
          {props.showError && <span className={`error big`}>{''}</span>}
        </div>
      </div>
    </div>
  );
}
