import parser from "fast-xml-parser";

export function onError(error) {
  return Promise.reject(error.response || error.message);
}

export function onSuccess(response) {
  console.debug('Request Successful!', response);
  if (typeof response.data !== 'object') {
    const result = parser.parse(response.data);
    return result ? result : response.data;
  } else {
    if (typeof response.headers['total-count'] !== 'undefined' && typeof response.headers['per-page'] !== 'undefined') {
      return {
        ...response.data,
        'per-page': response.headers['per-page'],
        'total-count': response.headers['total-count'],
        'total-pages': response.headers['total-pages'],
        'current-page': response.headers['current-page']
      }
    } else {
      return response.data;
    }
  }
}
