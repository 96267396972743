import { createSlice } from "@reduxjs/toolkit";

export const addonsSlice = createSlice({
  name: 'selected_addons_slice',
  initialState: {
    addons: [],
    selected_addons: {}
  },
  reducers: {
    update_addons: (state, action) => {
      state.addons = [...action.payload]
    },
    update_selected: (state, action) => {
      state.selected_addons = { ...action.payload }
    },
    clear_addons: (state) => {
      state.addons = []
    },
    clear_selected: (state) => {
      state.selected_addons = {}
    }
  }
})

export const { update_addons, update_selected, clear_addons, clear_selected } = addonsSlice.actions

export default addonsSlice.reducer