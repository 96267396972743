import { createSlice } from "@reduxjs/toolkit";

export const membershipSlice = createSlice({
  name: 'membership_slice',
  initialState: {
    memberships: {},
    selected_membership: {}
  },
  reducers: {
    update_memberships: (state, action) => {
      state.memberships = { ...state.memberships, ...action.payload }
    },
    clear_memberships: (state) => {
      state.memberships = {}
    },
    update_selected_membership: (state, action) => {
      state.selected_membership = { ...state.selected_membership, ...action.payload }
    },
    clear_selected_membership: (state) => {
      state.selected_membership = {}
    }
  }
})

export const { update_memberships, clear_memberships, update_selected_membership,  clear_selected_membership} = membershipSlice.actions

export default membershipSlice.reducer
