export const CLUBS = [
  {
    club_details: {
      id: 1,
      name: 'Golf Club 1',
      city: '1 Club Drive',
      state: 'NJ',
      zip_code: '12345',
      link: 'www.golfclub1.org',
      phone: '987654321',
      description: 'A membership for males between 14 and 30.',
      
    },
    fee_amount: 51.00,
    gender: 'male',
    min_age: 14,
    max_age: 30,
  },
  {
    club_details: {
      id: 2,
      name: 'Golf Club 2',
      city: '2 Club Drive',
      state: 'NJ',
      zip_code: '12345',
      link: 'www.golfclub2.org',
      phone: '987654321',
      description: 'A membership for females between 34 and 60.',
    },
    fee_amount: 39.00,
    gender: 'female',
    min_age: 34,
    max_age: 60,
  },
  {
    club_details: {
      id: 3,
      name: 'Golf Club 3',
      city: '3 Club Drive',
      state: 'NJ',
      zip_code: '12345',
      link: 'www.golfclub3.org',
      phone: '987654321',
      description: 'A membership for males and females between 64 and 80.',
    },
    fee_amount: 53.00,
    gender: 'male and female',
    min_age: 64,
    max_age: 80
  }
]
