import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import { clear_golfer, clear_address, clear_billing_address, clear_guardian, clear_email } from '../../features/golfer/golferSlice';
import { clear_club } from '../../features/club/clubSlice';
import { clear_memberships, clear_selected_membership } from '../../features/membership/membershipSlice';
import { clear } from '../../features/payment/paymentSlice';
import { clear_selected } from '../../features/addons/addonsSlice';

function ClubJoinPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearData = () => {
    sessionStorage.removeItem('auto_renew_failed');
    sessionStorage.removeItem('update_card_details');
    dispatch(clear_golfer());
    dispatch(clear_email());
    dispatch(clear_guardian());
    dispatch(clear_address());
    dispatch(clear_billing_address());
    dispatch(clear_club());
    dispatch(clear_memberships());
    dispatch(clear_selected_membership());
    dispatch(clear_selected());
    dispatch(clear());
  }

  useEffect(() => {
    clearData();
  }, [])

  return (
    <Fragment>
      <div>
        <div className="center">
          <span className="header-text">
            <p className="bold big-text align-text-center">
              Do you now or have you ever had a Handicap ID (GHIN number)?
            </p>
          </span>
        </div>
        <div className="box-panel center">
          <div
            style={{ marginTop: "15px", display: "flex", cursor: "pointer" }}
          >
            <div
              className="select_box"
              onClick={() => {
                navigate(`../verify_golfer`);
              }}
            >
              <i className="material-icons">done</i>
              <span className="label">Yes</span>
            </div>
            <div
              style={{ borderLeft: "none" }}
              className="select_box"
              onClick={() => {
                dispatch(clear_golfer());
                navigate(`../golfer`);
              }}
            >
              <i className="material-icons">close</i>
              <span className="label">No</span>
            </div>
          </div>
        </div>
        <div className='row'>
          {sessionStorage.getItem('entered_from_assoc_level') === 'true' &&
            <div className='col'>
              <button
                style={{marginTop: '10px', marginBottom: '20px', marginLeft: "auto", marginRight: "auto"}}
                className="btn smaller fill gray"
                type={"button"}
                onClick={() => {
                  navigate('../..');
                }}
              >
                Go Back
              </button>
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
}

export default ClubJoinPage;
