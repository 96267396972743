import React, {Fragment, useMemo, useCallback, useEffect, useContext} from "react";
import store from "../../store";
import {useDispatch} from 'react-redux'
import { clear_memberships, clear_selected_membership } from '../../features/membership/membershipSlice';
import { clear_addons, clear_selected } from '../../features/addons/addonsSlice';
import { clear_logged_in_user } from "../../features/logged_in_user/loggedInUserSlice";
import { clear_guardian } from '../../features/golfer/golferSlice';
import {useNavigate} from "react-router-dom";
import {JoinPageContext} from "../../context/join_page_context";

export default function Pending() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { JoinPageData } = useContext(JoinPageContext)

    const clearData = useCallback(() => {
    dispatch(clear_logged_in_user())
    dispatch(clear_guardian());
    dispatch(clear_addons());
    dispatch(clear_selected());
    dispatch(clear_memberships());
    dispatch(clear_selected_membership());
  }, [dispatch])

    const data = useMemo(() => {
    const store_data = store.getState();
    const values = {
      club_data: store_data.club.club_data,
      payment: store_data.payment.payment,
      golfer_data: store_data.golfer.golfer,
      address: store_data.golfer.address,
      promotional_codes: store_data.promotional_codes
    };
    clearData();

    return values
  }, [clearData])

  const computeDiscount = (fee, amount, type) => {
    if (type === "percentage") {
      return Math.min(fee * (amount / 100), fee);
    } else if (type === "dollar") {
      return Math.min(amount, fee);
    } else {
      return "invalid";
    }
  }

  const membership = data.payment.membership;
  const addons = data.payment.addons;
  const club_data = data.club_data;
  const golfer = data.golfer_data;
  const promotional_codes = data.payment.promotional_codes;
  const discount_code = promotional_codes.find(pc => pc.isDiscount);
  const discount_amount = discount_code ? computeDiscount(membership.amount, discount_code.discountAmount, discount_code.discountType) : 0;
  const addons_amount = addons.reduce( ( sum, addon ) => sum + parseFloat(addon.amount) , 0);
  const total_amount = (membership.amount + addons_amount - discount_amount > 0) ? (membership.amount + membership.transaction_fee + addons_amount - discount_amount) : 0;
  const promotions = promotional_codes.filter(pc => !pc.isDiscount).map(pc => pc.code);

  const getNavigationPath = () => {
    const slice_end = sessionStorage.getItem('entered_from_assoc_level') === 'true' ? 4 : 6;
    return window.location.href.split('/').slice(0, slice_end).join('/');
  }

  const PromoMessage = () => {
    let message;
    if (promotions.length === 1)
      message = `Promotion: ${promotions[0]} has been applied.`
    else {
      const last_promotion = promotions.pop()
      message = `Promotions: ${promotions.join(", ")} and ${last_promotion} have been applied.`
    }

    return message;
  };

  const getRerouteToLabel = () => {
    if (JoinPageData.reroute_to_label !== '')
      return JoinPageData.reroute_to_label;
    else if (sessionStorage.getItem('entered_from_assoc_level') === 'true')
      return JoinPageData.golf_association_details.name
    else
      return club_data.name
  }

  const getRerouteToUrl = () => {
    if (JoinPageData.reroute_to_url === "")
      return getNavigationPath();
    else
      return `//${JoinPageData.reroute_to_url}`;
  }

  return (
    <Fragment>
      <h1 className="page-title">
        Your registration has been submitted!
      </h1>
      <div className="payment-membership-title center_horizontal_margin top-margin large_bottom_margin">
        <div className="center_horizontal_margin">
          <div className="line-height-18" style={{ width: "90%", margin: "auto" }}>
            <p className="med_bottom_margin">
              Dear {`${golfer.first_name} ${golfer.last_name},`}
            </p>
            <p className="med_bottom_margin">
              Thank you for your interest in the {club_data.name}!
            </p>
            <p className="med_bottom_margin">
              Your registration is <strong>pending approval.</strong>
            </p>
              {total_amount !== 0 && <p className="med_bottom_margin">
                  Once approved, a charge of <span className="semi-bold">${parseFloat(total_amount).toFixed(2)}</span> will be applied to the payment method that you provided during registration.
              </p>}
            <p className={promotions.length === 0 ? "large_bottom_margin" : "med_bottom_margin"}>
              You will receive a separate email at that time confirming transaction and membership details.
            </p>
            {promotions.length > 0 && <p className="promotion-code-text semi-bold med_bottom_margin">
              {PromoMessage()}
            </p>}
            {(total_amount !== 0 || discount_code) && <table className="receipt-table">
              <tbody>
              <tr>
                <th>{membership.name}</th>
                <td>
                {
                    discount_code ?
                    <div className="codes-display-c">
                      <p style={{color: 'red'}}>${(parseFloat(membership.amount) - discount_amount).toFixed(2)}</p>
                      <p className="original-fee">Reg <span style={{textDecoration: 'line-through'}}>
                        ${(parseFloat(membership.amount)).toFixed(2)
                        }</span></p>
                      {
                        promotional_codes.filter(pc => pc.isDiscount).map((pc, index) => {
                          return (
                            <p key={pc.code} className="discount-code-text">{pc.code} Discount Applied</p>
                          )
                        })
                      }
                    </div>
                    :
                    `${membership.amount.toFixed(2)}`
                  }
                  </td>
              </tr>
              {addons.map((addon, index) => (
                  <tr key={index}>
                    <th>{addon.name}</th>
                    <td>${parseFloat(addon.amount).toFixed(2)}</td>
                  </tr>
              ))}
              {total_amount !== 0 && <tr>
                <th>{"Transaction Fee"}</th>
                <td>${membership.transaction_fee.toFixed(2)}</td>
              </tr>}
              <tr>
                <th style={{verticalAlign: "middle"}}>Total:</th>
                <td className="semi-bold">${parseFloat(total_amount).toFixed(2)}
                  <p className="pending-approval">Pending Approval</p>
                </td>
              </tr>
              </tbody>
            </table>}
              {total_amount !== 0 && <br/>}
            <p className="large_bottom_margin">
              If you have any questions, please contact {club_data.primary_contact} at the {club_data.name}.
            </p>
            <p>
              {club_data.email}
            </p>
            <p className="large_bottom_margin">
              {club_data.phone}
            </p>
            <button
              className="btn fit fill cardinal centered_btn"
              onClick={() => {window.location.replace(getRerouteToUrl())}}
            >
              Return to {getRerouteToLabel()}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
