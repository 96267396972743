import { createElement, useMemo } from "react";
import { JoinPageContextProvider } from "./join_page_context"

const ContextProvider = ({ children }) => {
  // If another context is required, just add it to the following array
  const contexts = useMemo(() => [
    JoinPageContextProvider
  ], [])

  const compact = useMemo(() => contexts.reduceRight(
    (prev, current) => createElement(current, {}, prev), children
  ), [contexts, children])
  return compact
}

export default ContextProvider
