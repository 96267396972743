import { useContext, useEffect, useState, useMemo, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import JoinPageService from '../services/api/join_page';
import { JoinPageContext } from '../context/join_page_context';
import InfiniteScroll from "react-infinite-scroll-component";
import RadioPicker from '../inputs/RadioPicker';
import InputField from '../inputs/InputField';
import { useDispatch } from 'react-redux';
import { clear_golfer, update_golfer, clear_address, clear_billing_address, clear_guardian, clear_email } from '../features/golfer/golferSlice';
import { clear_club } from '../features/club/clubSlice';
import { clear_memberships, clear_selected_membership } from '../features/membership/membershipSlice';
import { clear_selected } from '../features/addons/addonsSlice';
import { clear } from '../features/payment/paymentSlice';

import { CLUBS } from '../assets/dummy_data/clubs';
import Club from './Club';
import { debounce } from "@mui/material";

export default function FirstPage(props) {
  const [dateValue, setDateValue] = useState('');
  const [genderValue, setGenderValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [clubs, setClubs] = useState(null);
  const { url } = useParams();
  const { JoinPageData } = useContext(JoinPageContext);
  const [page, setPage] = useState(1);
  const [hasMoreClubs, setHasMoreClubs] = useState(false);
  const dispatch = useDispatch();
  const parse = require('html-react-parser');

  useEffect(() => {
    clearData();
  }, []);

  function onChangeDateValue(event) {
    setDateValue(event.target.value);
  }

  const debouncedChangeDateValue = useMemo(
    () => debounce(onChangeDateValue, 500), [onChangeDateValue]
  );

  function onChangeGenderValue(event) {
    setGenderValue(event.target.value);
  }

  function onChangeSearchValue(event) {
    setSearchValue(event.target.value);
  }

  const debouncedChangeSearchValue = useMemo(
    () => debounce(onChangeSearchValue, 500), [onChangeSearchValue]
  );

  const onClear = () => {
    setSearchValue('');
    setDateValue('');
    setGenderValue('');
  }

  useEffect(() => {
    onContinue();
  }, [searchValue, genderValue, dateValue])
  
  const onContinue = () => {
    dispatch(update_golfer({date_of_birth: dateValue, gender: genderValue}));
    getClubs(1);
  }

  const clearData = () => {
    sessionStorage.removeItem('auto_renew_failed');
    sessionStorage.removeItem('update_card_details');
    dispatch(clear_golfer());
    dispatch(clear_email());
    dispatch(clear_guardian());
    dispatch(clear_address());
    dispatch(clear_billing_address());
    // dispatch(clear_addons());
    dispatch(clear_selected());
    dispatch(clear_club());
    dispatch(clear_memberships());
    dispatch(clear_selected_membership());
    dispatch(clear());
  }

  const getClubs = (currentPage) => {
    if (props.preview === true) {
      getClubsDummy();
    } else {
      getClubsAPI(currentPage);
    }
  }

  const genderFilter = () => {
    if (genderValue === 'M') {
      return 'male';
    } else if (genderValue === 'F') {
      return 'female';
    }
  }

  const getClubsAPI = async (currentPage) => {
    let data = {
      'page': currentPage,
      'per_page': 10,
      'age': dateValue
    };

    setPage(currentPage);

    data['gender'] = genderFilter();

    if (postalCode() && clubName()) {
      if (verifyOnlyNumbers(searchValue)) {
        data['zip_code'] = searchValue;
      } else {
        data['club_name'] = searchValue;
      }
    } else if (postalCode()) {
      data['zip_code'] = searchValue;
    } else if (clubName()) {
      data['club_name'] = searchValue;
    }

    try {
      const response = await JoinPageService.getJoinClubPages(url, data);
      
      if (currentPage === 1) {
        setClubs(response['join_club_pages']);
      } else {
        setClubs((prev) => prev.concat(response['join_club_pages']));
      }

      const totalPages = Number(response['total-pages']);
      currentPage >= totalPages ? setHasMoreClubs(false) : setHasMoreClubs(true);
    
    } catch (error) {
      setClubs(null);
    }
  }

  const getClubsDummy = () => {
    let newClubs = filterByNameOrZipcode();
    const filter = genderFilter();

    if (filter === 'male' || filter === 'female') {
      newClubs = newClubs.filter(club => club.gender === filter || club.gender === 'male and female');
    }

    if (dateValue) {
      newClubs = newClubs.filter(club => club.min_age <= dateValue && dateValue <= club.max_age)
    }

    setClubs(newClubs);
  }

  const filterByNameOrZipcode = () => {
    if (postalCode() || clubName()) {
      if (verifyOnlyNumbers(searchValue)) {
        return CLUBS;
      } else {
        return CLUBS.filter(club => club.club_name.toLowerCase().indexOf(searchValue) >= 0)
      }
    }

    return CLUBS;
  }

  const showSearch = () => {
    return JoinPageData.club_name_search || JoinPageData.zip_code_search;
  }

  const postalCode = () => {
    return JoinPageData.zip_code_search;
  }

  const clubName = () => {
    return JoinPageData.club_name_search;
  }

  const searchLabel = () => {
    if (postalCode() === true && clubName() === true) {
      return 'Club Name or Zip Code';
    }else if (postalCode() === true) {
      return 'Zip Code';
    } else if (clubName() === true) {
      return 'Club Name';
    }
  }

  const verifyOnlyNumbers = (text) => {
    const regex = new RegExp('^[0-9]*$');
    return regex.test(text);
  }

  const addfullWidth = (htmlCode) => {
    if (htmlCode)
      if (htmlCode.props)
        if (htmlCode.props.style)
          htmlCode.props.style.width = '100%';

    return htmlCode;
  }

  const createAddress = (club_details) => {
    const separator = club_details.city === '' || club_details.city === null || club_details.city === undefined ? '' : ',';
    return `${club_details.city} ${separator} ${club_details.state} ${club_details.zip_code}`;
  }

  return (
    <Fragment>
      <div className='first-page-container grey-bottom-line'>
        <div className='header-text'>
          <div>
            {JoinPageData.header_text && addfullWidth(parse(JoinPageData.header_text))}
          </div>
        </div>

        {JoinPageData.header_text && <div style={{height: '20px'}}></div>}

        <div className='header-text'>
          {JoinPageData.description && addfullWidth(parse(JoinPageData.description))}
        </div>
      </div>
      
      <div className='first-page-container'>
        <form className="row filter-container ">
          <span className='translate-y'>Filter by:</span>
          {showSearch() && (
            <div className="text-under">
              <InputField
                size="medium responsive-on-tablet pedding-lr-10px"
                id="search"
                label={searchLabel()}
                value={searchValue}
                onChange={debouncedChangeSearchValue}
                autoComplete={"off"}
                type={clubName() ? "text" : "number"}
              />
              {postalCode() === true && <p>Zip Code search will list clubs within a 100-mile radius.</p>}
            </div>
          )}
          <InputField
            size='small responsive-on-tablet pedding-lr-10px'
            id='date'
            label={<>Age</>}
            value={dateValue}
            onChange={debouncedChangeDateValue}
            autoComplete={'off'}
            type={'number'}
          />
          <RadioPicker
            size='medium responsive-on-tablet pedding-lr-10px'
            id='gender'
            label={<>Gender</>}
            value={genderValue}
            onChange={onChangeGenderValue}
          />
        <button type='button' className='translate-y btn xx-smaller fill gray square btn-clear' onClick={onClear}>Clear</button>
        </form>

        <InfiniteScroll dataLength={clubs ? clubs.length : 0}
                        hasMore={hasMoreClubs}
                        loader={<span className="loader"></span>}
                        next={() => getClubs(page + 1)}
                        className='infinite' >
        {clubs && clubs.map((club) => <Club key={club.club_details.id}
                                            id={club.club_details.id}
                                            name={club.club_details.name}
                                            address={createAddress(club.club_details)}
                                            fee={club.fee_amount}
                                            link={club.club_details.link}
                                            phone={club.club_details.phone}
                                            club_details={club.club_details.description}
                                            preview={props.preview} />)}
          {clubs && clubs.length === 0 && <span> No clubs found.</span>}
        </InfiniteScroll>
      </div>
    </Fragment>
  )
}
